body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  display: flex;
  padding: 40px;
  justify-content: flex-start;
  font-size: 20px;
  border: 2px solid black;
}

button {
  cursor: pointer;
}

input[type="checkbox"] {
  cursor: pointer;
}

.subtitle {
  padding-left: 10px;
}

.taskItem {
  border: 1px solid;
  padding: 10px;
  margin: 10px;
}

.task {
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 5px;
  transition: all 0.3s ease 0s;
}

.task:hover {
  background-color: #cfdef3;
}

.arrow {
  transition: all 0.3s ease 0s;
}

.rotated {
  transform: rotate(90deg);
}

.taskDescription {
  font-weight: bold;
}
